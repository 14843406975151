import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import CurrencyField from "./fields/currency-field";
import CheckboxField from "./fields/checkbox-field";
import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import PercentageField from "./fields/percentage-field";
import NumberField from "./fields/number-field";
import Button from "../components/button";
import { TrashIcon } from "@heroicons/react/20/solid";

export default function ProductForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    const validate = (data) => {
        if (data.tieredPricing) {
            for (let t of data.tiers) {
                if (+t.maxPrice < +t.minPrice) return false;
            }
            if ((data.tiers?.map(z => +z.targetConversionPercentage).reduce((a, b) => a + b, 0)) != 100) return false;
        }
        else {
            if (+data.maxPrice < +data.minPrice) return false;
        }
        return true;
    }

    return <div>
        <PageHeader headline="Manage Product"></PageHeader>
        <FormWrapper validate={(data) => validate(data)} defaultValue={{ minimumEventsRequired: 10, maximumEventsReviewed: 200, step: 1, eventsBeforeChange: 10 }} url="app/product" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) =>
                <>
                    <TextField required label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })} />
                    <TextField label="Description" value={values.description} onChange={(val) => setValues({ ...values, description: val })} />
                    <CheckboxField label="Tiered Pricing Strategy" value={values.tieredPricing} onChange={(val) => setValues({ ...values, tieredPricing: val })} />
                    <div className="text-xs text-neutral-500">Check this box if you're pricing is using multiple tiers (such as a 3 tiered pricing model)</div>
                    <div className="my-5"></div>
                    {!values.tieredPricing && <>
                        <CurrencyField required label="Base Price" value={values.basePrice} onChange={(val) => setValues({ ...values, basePrice: val })} />
                        <div className="text-xs text-neutral-500">This is the starting, or current price, of your product</div>

                        <div className="grid grid-cols-2 gap-2">
                            <CurrencyField required label="Minimum Price" value={values.minPrice} onChange={(val) => setValues({ ...values, minPrice: val })} />
                            <CurrencyField required label="Maximum Price" value={values.maxPrice} onChange={(val) => setValues({ ...values, maxPrice: val })} />
                            {values.minPrice && values.maxPrice && +values.minPrice > +values.maxPrice && <div className="col-span-2 text-center text-red-500">Minimum price should be lower than maximum price</div>}

                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <NumberField required label="Target Conversion %" value={values.targetConversionPercentage} onChange={(val) => setValues({ ...values, targetConversionPercentage: val })} />

                                <div className="text-xs text-neutral-500">This is how many of your customers you want to optimally convert after viewing your pricing page</div>

                            </div>
                        </div>
                    </>}

                    {values.tieredPricing && <>

                        {values.tiers?.map((x, i) => <div className="p-5 rounded-lg border my-2 relative">
                            <div className="flex justify-between">

                                <div className="text-neutral-700 font-medium text-lg mb-2">Tier {i + 1}</div>
                                <div onClick={() => setValues({ ...values, tiers: values.tiers.filter((y, j) => j !== i) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>

                            </div>

                            <CurrencyField required label="Base Price" value={x.basePrice} onChange={(val) => setValues({ ...values, tiers: values.tiers.map((y, j) => j === i ? { ...y, basePrice: val } : y) })} />
                            <div className="text-xs text-neutral-500">This is the starting, or current price, of this tier</div>
                            <div className="grid grid-cols-2 gap-2">
                                <CurrencyField required label="Minimum Price" value={x.minPrice} onChange={(val) => setValues({ ...values, tiers: values.tiers.map((y, j) => j === i ? { ...y, minPrice: val } : y) })} />

                                <CurrencyField required label="Maximum Price" value={x.maxPrice} onChange={(val) => setValues({ ...values, tiers: values.tiers.map((y, j) => j === i ? { ...y, maxPrice: val } : y) })} />
                                {x.minPrice && x.maxPrice && +x.minPrice > +x.maxPrice && <div className="col-span-2 text-center text-red-500">Minimum price should be lower than maximum price</div>}
                            </div>
                            <NumberField required label="Target Sale %" value={x.targetConversionPercentage} onChange={(val) => setValues({ ...values, tiers: values.tiers.map((y, j) => j === i ? { ...y, targetConversionPercentage: val } : y) })} />
                            <div className="text-xs text-neutral-500">This is the percentage of customers you'd like to purchase this tier of pricing</div>
                        </div>)}

                        <Button text="Add Tier" onClick={() => setValues({ ...values, tiers: values.tiers ? values.tiers.concat([{}]) : [{}] })}></Button>
                    </>}
                    {values.tieredPricing && (values.tiers?.map(z => +z.targetConversionPercentage).reduce((a, b) => a + b, 0)) != 100 && <div className="col-span-2 text-center text-red-500">Tier conversion percentages must add up to 100% (currently {(values.tiers?.map(z => +z.targetConversionPercentage).reduce((a, b) => a + b, 0))}%)</div>}

                    <div className="my-5"></div>

                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <NumberField required label="Minimum Events Before Adjustment" value={values.minimumEventsRequired} onChange={(val) => setValues({ ...values, minimumEventsRequired: val, eventsBeforeChange: val })} />
                            <div className="text-xs text-neutral-500">This is the minimum number of events before robopricer will start to optimize your pricing</div>
                        </div>
                        <div>
                            <NumberField required label="Maximum Events Considered" value={values.maximumEventsReviewed} onChange={(val) => setValues({ ...values, maximumEventsReviewed: val })} />
                            <div className="text-xs text-neutral-500">This is the maximum number of events robopricer will consider during optimization (a smaller number will allow for more rapid changes, a larger number will mean a steadier price)</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <CurrencyField required label="Adjustment Step" value={values.step} onChange={(val) => setValues({ ...values, step: val })} />
                            <div className="text-xs text-neutral-500">This is how big the adjustment should be each time an optimization is made</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <CheckboxField label="Pause Price Adjustments" value={values.paused} onChange={(val) => setValues({ ...values, paused: val })} />
                            <div className="text-xs text-neutral-500">Check this box to pause price adjustments, you can turn them back on any time by unchecking this box.</div>
                        </div>
                    </div>
                </>
            }
        </FormWrapper>
    </div>
}