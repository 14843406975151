import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import ImageViewer from "./image-viewer";

export default function PageHeader({ imageSrc, image, headline, actions, showBackButton, subtitle }) {
    const navigate = useNavigate();
    return <div className="bg-white shadow relative">
        {showBackButton && <div onClick={() => navigate(-1)} className="bg-neutral-50 rounded-lg shadow absolute left-3 top-2 cursor-pointer hover:bg-neutral-200 transition"><ArrowLeftIcon className="w-8 text-neutral-500"></ArrowLeftIcon></div>}
        <div className="lg:mx-auto lg:max-w-6xl">
            <div className="py-3 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                <div className="min-w-0 flex-1">
                    <div className="flex items-center">
                        {imageSrc && <img
                            className="mr-3 hidden h-16 w-16 rounded-full sm:block"
                            src={imageSrc}
                        />}
                        {image && <ImageViewer image={image} className="mr-3 hidden h-16 w-16 rounded-full sm:block"></ImageViewer>}
                        <div>
                            <div className="flex flex-col lg:p-0 p-2">
                                <h1 className="text-2xl font-bold leading-7 text-neutral-800 sm:truncate sm:leading-9">
                                    {headline}
                                </h1>
                                <h3 className="text-lg font-semibold leading-7 text-neutral-600 sm:truncate sm:leading-9">{subtitle}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    {actions}
                </div>
            </div>
        </div>
    </div>
}