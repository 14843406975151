import { PlusIcon } from '@heroicons/react/20/solid'
import { Card, Flex, Metric, Subtitle, Text, Title } from '@tremor/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import { useNavigate } from 'react-router-dom'
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { api } from './api.service'
import Button from './components/button'
import Loading from './components/loading'

export default function Dashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState()

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://use.typekit.net/foobar.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app`).then(x => {
      if (x.redirectUrl) {
        window.location.replace(x.redirectUrl)
      }
      else {
        setData(x)
      }

    })
  }, [])

  if (!data) return <Loading></Loading>

  const getMultiSparklineData = (tiers) => {
    let output = [tiers[0]?.priceHistory?.length];
    let i = 0;
    for (let t of tiers) {
      let j = 0;
      if (t.priceHistory)
        for (let p of t.priceHistory) {
          output[j] = { ...output[j], [i]: p.price, date: new Date(p.date).getTime() }
          j++;
        }
      i++;
    }
    return output;
  }
  const getRevenueData = (events) => {
    let output = [];
    let cumulative = 0;
    for (let e of events) {
      cumulative += +(e.purchasePrice ?? 0)
      output.push({ "rev": cumulative, date: new Date(e.date).getTime() })
    }
    return output;
  }

  const calculateRevenueChange = () => {
    let base = 0;
    let current = 0;
    for (let p of data?.products) {
      if (p.tieredPricing) {
        for (let t of p.tiers) {
          base += +t.basePrice
          if (t.priceHistory)
            current += +(t.priceHistory[t.priceHistory.length - 1]?.price)
          else
            current += +t.basePrice
        }
      }
      else {
        base += +p.basePrice;
        if (p.priceHistory)
          current += +(p.priceHistory[p.priceHistory.length - 1]?.price)
        else
          current += +p.basePrice
      }
    }
    let res = Math.round(((current - base) / base * 100) * 100) / 100;
    if (isNaN(res) || !res || res <= 0) return 'Calculating...'
    return res + '%'
  }

  return (<>
    <div className="flex">
      <Helmet>
        <script>
          {`gtag('event', 'conversion', {
            'send_to': 'AW-11270416902/CPHTCKu-r8AYEIbUk_4p',
            'transaction_id': ''
            });`}
        </script>
      </Helmet>
      <div className="w-full max-w-5xl pb-20 mx-auto 2xl:max-w-6xl">
        <div className="mt-10"></div>
        <Title>Robopricer Dashboard</Title>
        <Text>Your robopricer dashboard gives you a quick overview of your products and their current pricing</Text>
        {!data.events?.length && <div className="p-3 my-8 text-lg font-medium text-center bg-white border rounded-lg shadow text-neutral-800">

          <div>View API Documentation To Integrate Robopricer</div>
          <div className="mt-2"><Button text="Go To Docs ->" onClick={() => navigate('/app/api')}></Button></div>
        </div>}

        <div className="mt-10"></div>

        {data.events?.length > 0 && <div className="grid grid-cols-3 gap-4 my-5">
          <Card className="max-w-xs mx-auto" decoration="top" decorationColor="green">
            <Subtitle>Total Events</Subtitle>
            <div className="flex space-x-1">
              <Metric>{data.events?.length}</Metric>
            </div>
          </Card>
          <Card className="max-w-xs mx-auto" decoration="top" decorationColor="green">
            <Subtitle>Total Purchases</Subtitle>
            <div className="flex space-x-1">
              <Metric>{data.events?.filter(x => x.eventType == 1).length}</Metric>
            </div>
          </Card>
          <Card className="max-w-xs mx-auto" decoration="top" decorationColor="green">
            <Subtitle>Projected Revenue Increase</Subtitle>
            <div className="flex space-x-1">
              <Metric>{calculateRevenueChange()}</Metric>
            </div>
          </Card>
        </div>}
        <Title>
          Products
        </Title>
        <div className="mb-5">
          {!data?.products?.length && <BlankState></BlankState>}
          {data.products?.map(x => <Card className="my-5" decoration="top" decorationColor="gray">

            <Title className="mb-5">{x.name}</Title>
            <Flex>
              <div className="w-44">
                <Text>
                  Starting Price
                  {!x.tieredPricing && <Metric>
                    ${x.basePrice}
                  </Metric>}
                  {x.tieredPricing && <Metric>
                    ${x.tiers.map(x => x.basePrice).join('/')}
                  </Metric>}
                </Text>
              </div>
              <div className="flex-1">
                {!x.tieredPricing && <Sparkline data={x.priceHistory}></Sparkline>}
                {x.tieredPricing && <MultiSparkline revData={getRevenueData(data.events?.filter(y => y.product == x._id))} data={getMultiSparklineData(x.tiers)}></MultiSparkline>}
              </div>
              <div className="text-right w-44">
                <Text>
                  Current Price
                  {!x.tieredPricing && <Metric>
                    ${x.priceHistory?.length > 0 ? x.priceHistory[x.priceHistory.length - 1].price : x.basePrice}
                  </Metric>}
                  {x.tieredPricing && <Metric>
                    ${x.tiers.map(y => y.priceHistory?.length > 0 ? y.priceHistory[y.priceHistory.length - 1].price : y.basePrice).join('/')}
                  </Metric>}
                </Text>
              </div>
            </Flex>

            <div className="mt-5">
              <Text>Recent Events</Text>
              {data.events?.filter(y => y.product == x._id).slice().reverse().filter((x, i) => i < 5).map(x => <div className="py-1 border-b">
                <div className="text-xs font-medium text-neutral-400">
                  {moment(x.date).local().format('MMMM Do, h:mma')}
                </div>
                <div className="text-neutral-700">
                  {x.eventType == 0 ? "Prospect" : x.eventType == 1 ? "Purchase" : ""} {x.purchaseString}
                </div>
                <div className="text-sm text-neutral-600">
                  {x.change}
                </div>
              </div>)}
            </div>


          </Card>)}
        </div>

      </div>
    </div>
  </>
  )
}
const colors = ["#22d3ee", "#a855f7", "#14b8a6", "#93c5fd"]

function Sparkline({ data }) {
  const _data = data.map(x => { return { count: x.price } });

  return (
    <ResponsiveContainer width={'100%'} height={120}>
      <LineChart data={_data}>
        <YAxis hide type="number" domain={['dataMin', 'dataMax']} />
        <Line dot={false} dataKey="count" stroke={colors[0]} strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  )
}

function MultiSparkline({ data, revData }) {
  return (
    <div className="relative">
      <ResponsiveContainer width={'100%'} height={120}>
        <LineChart data={data} >
          <YAxis hide type="number" domain={['dataMin', 'dataMax']} />
          <XAxis hide dataKey="date" type="time" />

          {data.map((x, i) => <Line dot={false} dataKey={i} stroke={colors[i]} strokeWidth={2} />)}
        </LineChart>
      </ResponsiveContainer>
      <div className="absolute top-0 w-full">
        <ResponsiveContainer width={'100%'} height={120}>
          <LineChart data={revData} >
            <YAxis hide type="number" domain={['dataMin', 'dataMax']} />
            <XAxis hide dataKey="date" type="time" />

            <Line dot={false} dataKey={"rev"} stroke={'#f59e0b'} strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </div>

    </div>

  )
}

function BlankState() {
  const navigate = useNavigate();
  return (
    <div className="text-center">
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No products</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new product.</p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/app/manage-product/new')}
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-green-600 rounded-md shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Product
        </button>
      </div>
    </div>
  )
}
