import { useState } from 'react'
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, CpuChipIcon, GlobeAltIcon, LightBulbIcon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from './logo.svg'
import logoDark from './logo-dark.svg'
import stripeLogo from './stripe.svg'
import shopifyLogo from './shopify.svg'
import paypalLogo from './paypal.svg'
import braintreeLogo from './braintree.svg'
import squareLogo from './square.svg'
import aydenLogo from './ayden.svg'
import screenshot from './screenshot.png'
import dashItems from './dash-items.png'
import {
  ArrowPathIcon,
  CheckIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'
import img4 from './4.png'

const tiers = [
  {
    name: 'Starter',
    id: 'tier-1',
    href: '#',
    priceMonthly: '$9',
    description: 'Starter includes',
    features: [
      '1 Product',
      'Automated Dynamic Pricing Optimization',
      'Realtime Pricing API',
      'Unlimited Events',
    ],
    featured: false,
  },
  {
    name: 'Premium',
    id: 'tier-2',
    href: '#',
    priceMonthly: '$29',
    description: 'Premium includes',
    features: [
      'Unlimited Products',
      'Unlimited Three-Tiered Products',
      'Automatic Algorithmic Price Optimization',
      'Realtime Pricing API',
      'Unlimited Events',
    ],
    featured: true,
  },
  {
    name: 'Ultimate',
    id: 'tier-3',
    href: '#',
    priceMonthly: '$45',
    description: 'Ultimate includes',
    features: [
      'Everything in Premium',
      'Priority support'
    ],
    featured: false,
  },
]
const faqs = [
  {
    question: "Does it work for subscriptions?",
    answer:
      "Robopricer is suggested to be used for single purchase products, however it can work for subscription based products too!",
  },
  {
    question: "Can I delete my data?",
    answer:
      "Yes, you have full control over all your data from within the Robopricer portal. You can also permanently delete all your data and your account by contacting us.",
  },
  {
    question: "Is payment secure?",
    answer:
      "Absolutely! Secure payment is managed by Lemon Squeezy, which is trusted by thousands of companies in 100+ countries.",
  },
  {
    question: "Are you GDPR compliant?",
    answer:
      "Yes, data privacy is our utmost concern, so we are GDPR compliant.",
  },
  {
    question: "Do you have an affiliate program?",
    answer:
      "Yes, join our affiliate program and receive a 30% commission on all payments within the first 12 months for paying customers you refer to us!",
  },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">


      <div className="relative w-full">
        <div className="fixed z-50 w-full">
          <div className="flex bg-neutral-200 text-neutral-500">
            <div className="flex justify-between w-full max-w-6xl p-1 mx-auto text-sm border-b">
              <div className="flex items-center space-x-3">
                <CheckIcon className="w-6 text-green-500"></CheckIcon> Founded in the USA. We respect your privacy.
              </div>
              <div className="flex items-center space-x-3">
                <LockClosedIcon className="w-6 text-orange-300"></LockClosedIcon> GDPR compliant
              </div>
            </div>
          </div>
          <header className="bg-white ">
            <div className="w-full max-w-6xl mx-auto">
              <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                  <a href="#" className="-m-1.5 p-1.5">
                    <img
                      className="h-auto w-44"
                      src={logo}
                      alt=""
                    />
                  </a>
                </div>
                <div className="flex lg:hidden">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-black">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="items-center hidden lg:flex lg:flex-1 lg:justify-end">

                  <a href="/login" className="text-sm font-semibold leading-6 text-black">
                    Log in
                  </a>
                  <a href="/register" className="p-2 px-5 ml-4 text-lg text-white bg-green-500 rounded shadow hover:bg-green-600">Optimize Pricing</a>

                </div>

              </nav>
              <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                  <div className="flex items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5">
                      <img
                        className="w-24 h-auto"
                        src={logo}
                        alt=""
                      />
                    </a>
                    <button
                      type="button"
                      className="-m-2.5 rounded-md p-2.5 text-gray-700"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flow-root mt-6">
                    <div className="-my-6 divide-y divide-gray-500/10">
                      <div className="py-6 space-y-2">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                      <div className="py-6">
                        <a
                          href="/login"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Log in
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Dialog>
            </div>

          </header>
        </div>
        {/* Header */}

      </div>

      <main>
        <Hero>

        </Hero>

        <div >
          <div className="px-6 mx-auto max-w-7xl lg:px-8">
            <div className="max-w-2xl mx-auto sm:text-center">
              <h2 className="text-base font-semibold leading-7 text-green-600">Don't Miss Out On Revenue</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Maximize product revenue while you sleep</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Optimize pricing in minutes with our pricing automation API that automatically maximizes revenue. Set pricing strategies and target conversion rates.
              </p>
            </div>
          </div>

        </div>

        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />

        {/* Pricing section */}
        <div id="pricing" className="relative px-6 mt-32 bg-white isolate sm:mt-56 lg:px-8">
          <div
            className="absolute inset-x-0 overflow-hidden -top-3 -z-10 transform-gpu px-36 blur-3xl"
            aria-hidden="true"
          >
            <div
              className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
            <h2 className="text-base font-semibold leading-7 text-green-600">Pricing</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Say goodbye to endless price tests and say hello to our pricing automation API
            </p>
          </div>
          <p className="max-w-2xl mx-auto mt-6 text-lg leading-8 text-center text-gray-600">
            Maximize your revenue through automated price optimization, without breaking the bank. We are
            growing with you, so our pricing is an affordable option for businesses of all sizes.
          </p>
          <div className="mt-16 font-bold tracking-tight text-center text-gray-700">7 Day Free Trial Then</div>

          <div className="grid items-center max-w-6xl grid-cols-1 mx-auto mt-8 gap-y-6 sm:mt-6 sm:gap-y-0 lg:max-w-1xl lg:grid-cols-3">
            {tiers.map((tier, tierIdx) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.featured ? 'relative bg-gray-900 shadow-2xl' : 'bg-white/60 sm:mx-8 lg:mx-0',
                  tier.featured
                    ? ''
                    : tierIdx === 0
                      ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
                      : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
                  'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'
                )}
              >
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.featured ? 'text-green-400' : 'text-green-600',
                    'text-base font-semibold leading-7'
                  )}
                >
                  {tier.name}
                </h3>
                <p className="flex items-baseline mt-4 gap-x-2">
                  <span
                    className={classNames(
                      tier.featured ? 'text-white' : 'text-gray-900',
                      'text-5xl font-bold tracking-tight'
                    )}
                  >
                    {tier.priceMonthly}
                  </span>
                  <span className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500', 'text-base')}>
                    /month
                  </span>
                </p>
                <p
                  className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-6 text-base leading-7')}
                >
                  {tier.description}
                </p>
                <ul
                  role="list"
                  className={classNames(
                    tier.featured ? 'text-gray-300' : 'text-gray-600',
                    'mt-8 space-y-3 text-sm leading-6 sm:mt-10'
                  )}
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className={classNames(
                          tier.featured ? 'text-green-400' : 'text-green-600',
                          'h-6 w-5 flex-none'
                        )}
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
                <a
                  href={'/register'}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.featured
                      ? 'bg-green-500 text-white shadow-sm hover:bg-green-400 focus-visible:outline-green-500'
                      : 'text-green-600 ring-1 ring-inset ring-green-200 hover:ring-green-300 focus-visible:outline-green-600',
                    'mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10'
                  )}
                >
                  Get started today
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ section */}
        <div id="faqs" className="px-6 mx-auto mt-32 max-w-7xl sm:mt-56 lg:px-8">
          <div className="max-w-4xl mx-auto divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.question}</span>
                          <span className="flex items-center ml-6 h-7">
                            {open ? (
                              <MinusSmallIcon className="w-6 h-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="w-6 h-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="pr-12 mt-2">
                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </main>

      <Footer></Footer>
    </div>
  )
}


const navigation = [
  { name: 'How it works', href: '#hiw' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Affiliates', href: 'https://robopricer.lemonsqueezy.com/affiliates' },
]

function Hero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">
      {/* <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="w-auto h-8"
                src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="w-auto h-8"
                  src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="w-6 h-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root mt-6">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="py-6 space-y-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header> */}
      <div className="relative overflow-hidden isolate bg-gradient-to-b from-green-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-green-600/10 ring-1 ring-green-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="px-6 py-32 mx-auto max-w-7xl sm:py-40 lg:px-8">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
            Automatic Price Optimization
            </h1>

            <div className="max-w-xl mt-6 lg:mt-0 xl:col-end-1 xl:row-start-1">
              <h3 className="mb-4 text-xl font-medium text-gray-600">
                Is setting your price difficult?
              </h3>
              <p className="text-lg leading-8 text-gray-600">
              Robopricer automatically updates your pricing in real time to achieve an optimal conversion rate, or tiered purchase breakdown, to maximize your revenue.
              </p>
              <div className="mt-5 text-xs font-medium text-gray-400">Integrates with</div>

              <div className="flex items-center space-x-4 marker:mt-5">
                <div><img src={stripeLogo} className="w-24"></img></div>
                <div><img src={shopifyLogo} className="w-24"></img></div>
                <div><img src={paypalLogo} className="w-24"></img></div>
                <div><img src={braintreeLogo} className="w-24"></img></div>
                <div><img src={squareLogo} className="w-24"></img></div>
                <div><img src={aydenLogo} className="w-24"></img></div>
              </div>
              <div className="flex items-center mt-10 gap-x-6">
                <a
                  href="/register"
                  className="px-6 py-4 text-xl font-semibold text-white bg-green-600 rounded-md shadow-sm cursor-pointer hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Get started for free
                </a>
              </div>
            </div>
            <div className="relative w-full mt-10 sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-12"
            >
              <img
                src={dashItems}
                alt=""
                className="lg:absolute w-full lg:w-[40vw] rounded-2xl object-cover"
              />
            </div>

          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 h-24 -z-10 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  )
}

function Step1() {
  return (
    <div id="hiw" className="py-24 overflow-hidden bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="grid max-w-2xl grid-cols-1 mx-auto mt-10 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-green-600">Quick to setup</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Automate pricing in minutes</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Create your unique API key. Set pricing strategies for single products or three-tier products. Create rules
                based on target conversion rates, percentage of sales, size of price adjustment, and more.
              </p>
              <div className="mt-5">
                <a
                  href="/register"
                  className="px-4 py-3 font-semibold text-white bg-green-600 rounded-md shadow-sm cursor-pointer hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Try it for free
                </a>
              </div>

            </div>
          </div>
          <img
            src={img1}
            alt="Product screenshot"
            className="w-[44rem] max-w-none sm:mt-16"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  )
}

function Step2() {
  return (
    <div className="py-24 overflow-hidden bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-green-600">Easy to manage</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A dashboard to manage your revenue</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Simple &amp; easy-to-manage dashboard for your API keys and pricing strategies. View the increase in
                revenue from optimized pricing with ease.
              </p>
              <div className="mt-5">
                <a
                  href="/register"
                  className="px-4 py-3 font-semibold text-white bg-green-600 rounded-md shadow-sm cursor-pointer hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Try it for free
                </a>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={img2}
              alt="Product screenshot"
              className="w-[44rem] max-w-none"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Step3() {
  return (
    <div className="py-24 overflow-hidden bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="grid max-w-2xl grid-cols-1 mx-auto mt-10 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-green-600">One-time purchase products</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Perfect for any product</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Whether you’re selling the greatest SEO course or the latest dropshipping hit, our API will optimize your
                one-time purchase product pricing based on targets you create.
              </p>
              <div className="mt-5">
                <a
                  href="/register"
                  className="px-4 py-3 font-semibold text-white bg-green-600 rounded-md shadow-sm cursor-pointer hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Try it for free
                </a>
              </div>

            </div>
          </div>
          <img
            src={img3}
            alt="Product screenshot"
            className="max-w-none w-[44rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  )
}

function Step4() {
  return (
    <div className="py-24 overflow-hidden bg-white sm:py-32">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-green-600">Three-tier products</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Take out the guesswork</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                No more guessing how to price your tiered products. Our Robopricer API will optimize your tiered
                product pricing in real time based on rules you set, such as percentage of sales by tier.
              </p>
              <div className="mt-5">
                <a
                  href="/register"
                  className="px-4 py-3 font-semibold text-white bg-green-600 rounded-md shadow-sm cursor-pointer hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  Try it for free
                </a>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={img4}
              alt="Product screenshot"
              className="max-w-none w-[44rem] sm:mt-24 "
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


function Footer() {
  return (
    <footer className="bg-white mt-14">
      <div className="px-6 py-12 mx-auto max-w-7xl md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <a  className="text-xs leading-5 text-center text-gray-500" href="mailto:support@robopricer.com">support@robopricer.com</a>
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-xs leading-5 text-center text-gray-500">
            &copy; 2023 Robopricer
          </p>
        </div>
      </div>
    </footer>
  )
}
