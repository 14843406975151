import PageHeader from "../components/page-header";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import CurrencyField from "./fields/currency-field";
import CheckboxField from "./fields/checkbox-field";
import { useNavigate, useParams } from "react-router-dom";
import SelectField from "./fields/select-field";
import PercentageField from "./fields/percentage-field";
import NumberField from "./fields/number-field";
import Button from "../components/button";
import { TrashIcon } from "@heroicons/react/20/solid";

export default function EventForm(props) {
    const params = useParams();
    const navigate = useNavigate();
    let id = params.id ?? props.id;

    return <div>
        <PageHeader headline="Manage Event"></PageHeader>
        <FormWrapper url="app/event" id={id} {...props} callback={() => navigate(-1)}>
            {(values, setValues) =>
                <>
                    <SelectField options={[{ text: 'Prospect Viewed Pricing', value: 0 }, { text: 'Purchase', value: 1 }]} label="Event Type" value={values.eventType} onChange={(val) => setValues({ ...values, eventType: val })}></SelectField>
                    <SelectField optionListName={'products'} label="Product" value={values.product} onChange={(val) => setValues({ ...values, product: val })}></SelectField>
                    <TextField label="Customer Identifier" value={values.identifier} onChange={(val) => setValues({ ...values, identifier: val })} />
                    
                    <TextField label="Tier" value={values.tier} onChange={(val) => setValues({ ...values, tier: val })} />

                </>
            }
        </FormWrapper>
    </div>
}