import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'

export default function AdminDashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState()

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/agent`).then(x => {
      if (x.status == "no agent") {
        navigate('/app/profile')
      }
      else {
        setData(x)
      }
    })
  }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={"Dashboard"} ></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto">
        <div className="bg-white rounded shadow p-10">
          <div className="text-green-500 font-semibold mb-5 text-3xl text-center">Welcome to the RealtyView Admin Portal</div>


        </div>


      </div>
    </div>
  </>
  )
}