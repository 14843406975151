import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'

export default function Api() {
  const navigate = useNavigate();
  const [data, setData] = useState()

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app/api-details`).then(x => {
      setData(x)
    })
  }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <PageHeader headline={"API"} ></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 2xl:max-w-6xl max-w-5xl mx-auto">

        <div className="bg-white rounded shadow p-10 m-5">
          <div className="text-xl tracking-tight font-semibold text-neutral-800">
            Overview
          </div>
          <div className="text-neutral-600 my-2">Welcome to the robopricer API documentation. The robopricer API will let your website let robopricer know about new events, and load the latest optimal pricing information.</div>

          <div className="text-neutral-600 my-2">
            <div className="font-semibold">The API has two main purposes.</div>
            <div className="my-1">
              The first is to send event data to robopricer. This should happen each time a user is marked as a conversion prospect (for example at the point they land on the purchase page), and each time a purchase happens.
            </div>
            <div className="my-1">
              The second is to load up to date pricing information. You can use an endpoint to load and display the current optimal pricing for your product.
            </div>
          </div>
        </div>

        <div className="bg-white rounded shadow p-10 m-5">
          <div className="text-xl tracking-tight font-semibold text-neutral-800">
            General
          </div>
          <div className="text-neutral-600 my-2">You should only make calls to the API from your backend, and you should keep your API key secret. </div>
          <div className="text-neutral-600 my-2">It's up to you to decide what you view as a prospect, you can decide to add a prospect event for every person that lands on your homepage, or it could be anyone who starts the checkout process.  </div>

        </div>

        <div className="bg-white rounded shadow p-10 m-5">
          <div className="text-xl tracking-tight font-semibold text-neutral-800">
            Authentication
          </div>
          <div className="text-neutral-600 my-2">You must send your unique API key with each API request in the X-API-KEY header. This key is used for both authentication and identification.</div>
          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">Your API Key = {data.key}</div>
        </div>

        <div className="bg-white rounded shadow p-10 m-5">
          <div className="text-xl tracking-tight font-semibold text-neutral-800">
            Loading Pricing
          </div>

          <div className="text-neutral-600 my-2">Use this endpoint to load the current pricing for your product. You can find the product id in the products page. This endpoint will either return a single number for single tier pricing, or an array of numbers for multi tier pricing.</div>

          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">GET</div>
            https://api.robopricer.com/api/pricing/{'{product_id}'}
          </div>

          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">Example Response (single tier pricing)</div>
            12.99
          </div>
          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">Example Response (multi tier pricing)</div>
            [5, 15, 25]
          </div>
        </div>

        <div className="bg-white rounded shadow p-10 m-5">
          <div className="text-xl tracking-tight font-semibold text-neutral-800">
            Sending Events
          </div>



          <div className="font-bold text-neutral-700 text-xs">Single Tier</div>
          <div className="text-neutral-600 my-2">For multi tier pricing use these endpoints. Use the prospect endpoint to add a prospect event, and a purchase endpoint to add a purchase event.</div>

          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">POST</div>
            https://api.robopricer.com/api/events/prospect/{'{product_id}'}
          </div>
          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">POST</div>
            /api/events/purchase/{'{product_id}'}
          </div>

          <div className="font-bold text-neutral-700 text-xs">Multi Tier</div>
          <div className="text-neutral-600 my-2">For multi tier pricing use these endpoints. Use the prospect endpoint to add a prospect event, and a purchase endpoint to add a purchase event. The tier_index value should be the 1 based index, so for example in a 3 tier priced product the first tier would be 1, the second 2, and the third 3.</div>

          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">POST</div>
            https://api.robopricer.com/api/events/prospect/{'{product_id}'}/{'{tier_index}'}
          </div>
          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">POST</div>
            https://api.robopricer.com/api/events/purchase/{'{product_id}'}/{'{tier_index}'}
          </div>

          <div className="text-neutral-600 my-2">All of the endpoints above will return a status code of 200 if successful, or an error code if unsuccessful.</div>
          <div className="text-neutral-600 my-5 p-3 bg-neutral-100 shadow-inner">
            <div className="font-bold text-neutral-700 text-xs">Successful Example Response</div>
            HTTP 200
          </div>
        </div>

      </div>


    </div>
  </>
  )
}