import { Routes, Route } from "react-router-dom";
import AdminDashboard from "./admin-dashboard";
import AdminLayout from "./admin-layout";
import AdminUsers from "./admin-users";
import Dashboard from "./dashboard";
import UserForm from "./forms/user-form";
import Layout from "./layout";
import Login from "./login";
import Logout from "./logout";
import Register from "./register";
import RequestReset from "./request-reset2";
import ResetPassword from "./reset-password2";
import Landing from "./landing";
import Products from "./products";
import ProductForm from "./forms/product-form";
import Events from "./events";
import Api from "./api";
import EventForm from "./forms/event-form";

export default function App() {

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />
      <Route path="/logout" element={<Logout />} />

      <Route path='/app' element={<Layout />}>
        <Route path="/app" element={<Dashboard />} />
        <Route path="/app/products" element={<Products></Products>} />
        <Route path="/app/manage-product/:id" element={<ProductForm></ProductForm>} />
        <Route path="/app/events" element={<Events></Events>} />
        <Route path="/app/manage-event/:id" element={<EventForm></EventForm>} />
        <Route path="/app/api" element={<Api />} />

      </Route>

      <Route path='/admin' element={<AdminLayout></AdminLayout>}>
        <Route path="/admin" element={<AdminDashboard></AdminDashboard>} />
        <Route path="/admin/users" element={<AdminUsers></AdminUsers>} />
        <Route path="/admin/manage-user/:id" element={<UserForm></UserForm>} />
      </Route>
    </Routes>
  )
}
