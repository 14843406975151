import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import Table from './components/table'
import Confirm from './components/confirm'
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid'
import moment from 'moment'

export default function Events() {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  const [products, setProducts] = useState(null)
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/app/event/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/app/event`).then(x => { setData(x.events); setProducts(x.products) }).then(x => setDeleteId(null))
    });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/app/event`).then(x => { setData(x.events); setProducts(x.products) }) }, [])

  if (!data) return <Loading></Loading>

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

    <PageHeader subtitle={"Manage your events here"} headline={"Events"} actions={<Button text="Add Event" onClick={() => navigate('/app/manage-event/new')}></Button>}></PageHeader>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        <div>
          <Table
            data={data.slice().reverse().map(x => {
              return {
                ...x, name: <div>

                  <div className="text-xs font-medium text-neutral-400">
                    {moment(x.date).local().format('MMMM Do, h:mma')}
                  </div>
                  <div className="text-sm text-neutral-700 font-medium">
                    {products.find(y => y._id == x.product)?.name}
                  </div>
                  <div className="text-neutral-700">
                    {x.eventType == 0 ? "Prospect" : x.eventType == 1 ? "Purchase" : ""} {x.purchaseString}
                  </div>
                  <div className="text-sm text-neutral-600">
                    {x.change}
                  </div>

                </div>,
                edit: <span className="cursor-pointer underline" onClick={() => navigate(`/app/manage-event/${x._id}`)}><PencilIcon className="w-6 text-neutral-400 hover:text-neutral-600"></PencilIcon></span>,
                deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}><TrashIcon className="w-6 text-neutral-400 hover:text-neutral-600"></TrashIcon></span>
              }
            })} columns={[
              {
                Header: 'Name',
                accessor: 'name',
                width: 400
              },
              {
                Header: 'Edit',
                accessor: 'edit',
                width: 20
              },
              {
                Header: 'Delete',
                accessor: 'deleteCell',
                width: 20
              },
            ]}></Table>
        </div>
      </div>
    </div>
  </>
  )
}