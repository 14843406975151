import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginCheck } from './api.service';
import logo from './logo.svg'

export default function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values })
    }).then(res => { return res.json() }).then(x => {
      if (x.token) {
        localStorage.setItem("token", x.token);

        if (searchParams.get('rd')) {
          window.location.replace(searchParams.get('rd'))
        }
        else {
          window.location.replace(x.redirectUrl)
        }
      }
      else {
        setError("Login failed. Please check your details and try again.")
      }
    })
  }

  useEffect(() => { loginCheck(`${process.env.REACT_APP_API_URL}/app`).then(x => (x) ? navigate('/app') : {}) }, [])

  return (
    <>
      <div className="flex h-screen flex-col bg-slate-50">
        <div className="flex flex-1 flex-col justify-center max-h-screen">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto w-64 h-auto mb-2"
              src={logo}
            />
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="bg-white rounded py-8 px-4 shadow sm:px-10">
              <div className="font-medium text-xl text-neutral-800 text-center">Sign In</div>
              <div className="font-light text-sm text-neutral-500 text-center my-5">Don't have an account? <a className="text-green-400 hover:text-green-500" href="/register">Sign Up</a></div>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-neutral-800 text-center">
                    {error}
                  </label>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-800">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-neutral-800">
                    Password
                  </label>
                  <div className="mt-1">
                    <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded border border-neutral-300 px-3 py-2 placeholder-neutral-400 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <a href="/request-reset" className="font-medium text-green-600 hover:text-green-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    onClick={() => submit()}
                    type="button"
                    className="flex rounded  w-full justify-center border border-transparent bg-green-500 py-2 px-4 text-md font-semibold text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Login
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}
