import { useEffect, useRef, useState } from "react"
import Button from "../components/button";
import TextField from "./fields/text-field";
import { api } from '../api.service.js'
import Loading from "../components/loading";

export default function FormWrapper({ url, id, callback, children, includeIdInPost, buttonText, hideButton, noStyle, defaultValue, noId, noLoad, validate }) {
    const [values, setValues] = useState();
    let formRef = useRef();
    useEffect(() => {
        if (noId) {
            if (id) {
                setValues(defaultValue ?? {})
            }
            else if (!noLoad) {
                api(`${process.env.REACT_APP_API_URL}/${url}`).then(x => {
                    setValues(x ?? {});
                });
            }
            else {
                setValues(defaultValue ?? {})
            }
        }
        else {
            if (id !== "new")
                api(`${process.env.REACT_APP_API_URL}/${url}/${id}`).then(x => {
                    setValues(x ?? {});
                });
            else {
                setValues(defaultValue ?? {})
            }
        }

    }, [])

    const submit = (data) => {
        if (validate && !validate(values)) return;
        delete values.priceHistory;
        if (values.tiers)
            for (let t of values.tiers) {
                delete t.priceHistory
            }

        if (includeIdInPost && !noId) {
            api(`${process.env.REACT_APP_API_URL}/${url}/${id}`, { data: data ?? values }).then(x => {
                callback();
            });
        }
        else {
            api(`${process.env.REACT_APP_API_URL}/${url}`, { data: data ?? values }).then(x => {
                callback();
            });
        }

    }

    if (!values) return <Loading></Loading>
    return <div className={noStyle ? '' : '2xl:max-w-6xl max-w-5xl mx-auto my-5 bg-white shadow rounded p-5'}>
        <form onSubmit={(e) => { e.preventDefault(); submit() }}>
            {children(values, setValues, submit)}
            {!hideButton && <button className="inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white bg-green-500 border border-transparent rounded shadow-sm cursor-pointer hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">{buttonText ?? 'Submit'}</button>}
        </form>

    </div>
}